<template>
  <div class="mobile">
    <div class="mobile-meau">
      <div class="mobile-meau-title">
        <div class="mobile-meau-title-empty"></div>
        <div class="mobile-meau-title-text">泊律恆</div>
        <div class="mobile-meau-title-button" @click="openMenu()">
          <div class="mobile-meau-title-button-main"></div>
        </div>
      </div>
      <div class="mobile-meau-list" :class="{ 'd-none': !isMenuOpen }">
        <button
          type="button"
          class="mobile-meau-list-item"
          @click="gotoPage('introduction')"
        >
          公司介紹
        </button>
        <button
          type="button"
          class="mobile-meau-list-item"
          @click="gotoPage('product')"
        >
          產品介紹
        </button>
        <button
          type="button"
          class="mobile-meau-list-item"
          @click="gotoPage('unify')"
        >
          服務概況
        </button>
        <button
          type="button"
          class="mobile-meau-list-item"
          @click="gotoPage('about')"
        >
          聯絡我們
        </button>
        <div class="mobile-meau-list-copyright">copyright © Berryan Inc.</div>
      </div>
    </div>
    <div class="mobile-content">
      <div class="mobile-content-introduction" id="introduction">
        <div class="mobile-content-introduction-group">
          <div class="mobile-content-introduction-group-title">主要發展</div>
          <div class="mobile-content-introduction-group-field">
            <div class="mobile-content-introduction-group-field-word">
              公司主要從事停車管理服務業
            </div>
            <div class="mobile-content-introduction-group-field-word">
              至今SmartPark System管理系統已服務數百個停車場站
            </div>
            <div
              class="mobile-content-introduction-group-field-word margin-bottom"
            >
              並開發PACA雲端資訊整合系統，提供企業最佳解決方案
            </div>
          </div>
        </div>
        <div class="mobile-content-introduction-group">
          <div class="mobile-content-introduction-group-title">延伸領域</div>
          <div class="mobile-content-introduction-group-field">
            <div class="mobile-content-introduction-group-field-word">
              我們同時提供系統開發服務
            </div>
            <div class="mobile-content-introduction-group-field-word">
              為客戶規畫與打造所需的功能及流程
            </div>
            <div class="mobile-content-introduction-group-field-word">
              提供專業友善的系統介面以貼近使用者需求
            </div>
            <div class="mobile-content-introduction-group-field-word">
              協助客戶達到開創業務之目標
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-content-product" id="product">
        <div class="mobile-content-product-title">
          停車場管理系統：大小型停車場都適用
        </div>
        <div
          id="product_carousel"
          class="carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#product_carousel"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#product_carousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#product_carousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#product_carousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#product_carousel"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#product_carousel"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-bs-target="#product_carousel"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="8000">
              <div class="mobile-content-product-content-item item-1">
                <div class="mobile-content-product-content-item-title">
                  多車種
                </div>
                <div class="mobile-content-product-content-item-text">
                  提供轎車、機車、重機、電動車...等，各類車種獨立計費功能
                </div>
                <div
                  class="mobile-content-product-content-item-img img-1"
                ></div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-product-content-item item-2">
                <div class="mobile-content-product-content-item-title">
                  多重計費
                </div>
                <div class="mobile-content-product-content-item-text">
                  支援平日、假日、日間、夜間、隔夜...等，多時段計費方式。提供臨停、月租、會員，各種計費方案
                </div>
                <div
                  class="mobile-content-product-content-item-img img-2"
                ></div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-product-content-item item-3">
                <div class="mobile-content-product-content-item-title">
                  多重折扣
                </div>
                <div class="mobile-content-product-content-item-text">
                  配合店家、賣場、銀行信用卡...等活動，進行優惠折抵
                </div>
                <div
                  class="mobile-content-product-content-item-img img-3"
                ></div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-product-content-item item-4">
                <div class="mobile-content-product-content-item-title">
                  可單機作業
                </div>
                <div class="mobile-content-product-content-item-text">
                  具單機獨立作業能力，網路中斷後，仍可繼續運作。不論上線、下線皆可輕鬆控管
                </div>
                <div
                  class="mobile-content-product-content-item-img img-4"
                ></div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-product-content-item item-5">
                <div class="mobile-content-product-content-item-title">
                  黑名單控制
                </div>
                <div class="mobile-content-product-content-item-text">
                  針對特定或違規車輛，進行黑名單管制，安全掌控車輛進出
                </div>
                <div
                  class="mobile-content-product-content-item-img img-5"
                ></div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-product-content-item item-6">
                <div class="mobile-content-product-content-item-title">
                  停車費試算
                </div>
                <div class="mobile-content-product-content-item-text">
                  線上停車費查詢，讓你不論身處何處，只要輸入車牌號碼，立即查看已停留時數及應繳停車費用
                </div>
                <div
                  class="mobile-content-product-content-item-img img-6"
                ></div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-product-content-item item-7">
                <div class="mobile-content-product-content-item-title">
                  憑證整合
                </div>
                <div class="mobile-content-product-content-item-text">
                  整合各場站所有憑證，讓您一站掌控所有資訊只要一個帳號，輕鬆瀏覽所有場站現況，是策略分析、狀況處理的最佳幫手
                </div>
                <div
                  class="mobile-content-product-content-item-img img-7"
                ></div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#product_carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#product_carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="mobile-content-unify" id="unify">
        <div class="mobile-content-unify-title">服務概況：即時資訊分析</div>
        <div
          id="unify_carousel"
          class="mobile-content-unify-carousel carousel carousel-dark slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#unify_carousel"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#unify_carousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#unify_carousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#unify_carousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#unify_carousel"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="8000">
              <div class="mobile-content-unify-item identity">
                <div class="mobile-content-unify-item-title">在場客戶</div>
                <div class="mobile-content-unify-item-identity">
                  <apexchart
                    v-if="pieSeries.length != 0"
                    type="radialBar"
                    height="350"
                    :options="pieChartOptions"
                    :series="pieSeries"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-unify-item inout">
                <div class="mobile-content-unify-item-title">每日車流量</div>
                <div class="mobile-content-unify-item-inout">
                  <apexchart
                    v-if="lineSeries.length != 0"
                    type="line"
                    height="350"
                    :options="lineChartOptions"
                    :series="lineSeries"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-unify-item inout">
                <div class="mobile-content-unify-item-title">
                  <span class="mobile-content-unify-item-title-name"
                    >各時段流量</span
                  >
                  <select
                    class="mobile-content-unify-item-title-select"
                    v-model="timeCheckDay"
                    @change="drowTimeCheckChart()"
                  >
                    <option
                      v-for="(item, index) in timeCheckDaysOption"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                  <span class="mobile-content-unify-item-title-description">
                    <span
                      class="mobile-content-unify-item-title-description-label"
                    ></span>
                    {{ timeCheckDescription }}
                  </span>
                </div>
                <div class="mobile-content-unify-item-inout">
                  <apexchart
                    v-if="timeCheckSeries.length != 0"
                    type="area"
                    height="350"
                    :options="timeCheckOptions"
                    :series="timeCheckSeries"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-unify-item status">
                <div class="mobile-content-unify-item-title">營收分析</div>
                <div
                  class="mobile-content-unify-item-pay-last"
                  v-if="lastSecondWeekDaysIncome.length != 0"
                >
                  <div class="mobile-content-unify-item-pay-last-total">
                    <div class="mobile-content-unify-item-pay-last-total-money">
                      {{
                        [
                          parseInt(lastSecondWeekDaysIncome[13].total),
                        ].toLocaleString()
                      }}
                    </div>
                    <div class="mobile-content-unify-item-pay-last-total-day">
                      {{ lastSecondWeekDaysIncome[13].date }} 收入
                    </div>
                  </div>
                  <div class="mobile-content-unify-item-pay-last-count">
                    <div class="mobile-content-unify-item-pay-last-count-item">
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-label"
                      >
                        現金
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-value"
                      >
                        {{ [paysCompare.aps.value].toLocaleString() }}
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-icon"
                        :class="[paysCompare.aps.icon]"
                      ></div>
                    </div>
                    <div class="mobile-content-unify-item-pay-last-count-item">
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-label"
                      >
                        會員
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-value"
                      >
                        {{ [paysCompare.fetc.value].toLocaleString() }}
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-icon"
                        :class="[paysCompare.fetc.icon]"
                      ></div>
                    </div>
                    <div class="mobile-content-unify-item-pay-last-count-item">
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-label"
                      >
                        刷卡
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-value"
                      >
                        {{ [paysCompare.card.value].toLocaleString() }}
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-icon"
                        :class="[paysCompare.card.icon]"
                      ></div>
                    </div>
                    <div class="mobile-content-unify-item-pay-last-count-item">
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-label"
                      >
                        儲值
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-value"
                      >
                        {{ [paysCompare.deposit.value].toLocaleString() }}
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-icon"
                        :class="[paysCompare.deposit.icon]"
                      ></div>
                    </div>
                    <div class="mobile-content-unify-item-pay-last-count-item">
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-label"
                      >
                        行動支付
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-value"
                      >
                        {{ [paysCompare.paytaipei.value].toLocaleString() }}
                      </div>
                      <div
                        class="mobile-content-unify-item-pay-last-count-item-icon"
                        :class="[paysCompare.paytaipei.icon]"
                      ></div>
                    </div>
                    <div
                      class="mobile-content-unify-item-pay-last-count-description"
                    >
                      與前一日相比
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <div class="mobile-content-unify-item predict">
                <div class="mobile-content-unify-item-title">營收預測</div>
                <div class="mobile-content-unify-item-predict">
                  <apexchart
                    class="home-unify-pay-chart-item"
                    v-if="paySeries[0].data.length != 0"
                    type="line"
                    height="320"
                    :options="payChartOptions"
                    :series="paySeries"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#unify_carousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#unify_carousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div class="mobile-content-unify-heat">
          <div class="mobile-content-unify-heat-title">
            <div class="mobile-content-unify-heat-title-text">使用率概況</div>
          </div>
          <div class="mobile-content-unify-heat-control">
            <span
              class="mobile-content-unify-heat-control-text"
              :class="{ sunColor: !isViewNightHeatData }"
              >日間</span
            >
            <label>
              <input
                type="checkbox"
                class="checkbox"
                v-model="isViewNightHeatData"
                @change="switchHeatData"
              />
              <span class="btn-box">
                <span class="btn"></span>
              </span>
              <!-- <span class="text">{{isViewNightHeatData ? '查看夜間分布' : '查看日間分布'}}</span> -->
            </label>
            <span
              class="mobile-content-unify-heat-control-text"
              :class="{ nightColor: isViewNightHeatData }"
              >夜間</span
            >
          </div>
          <div class="mobile-content-unify-heat-useing">
            <apexchart
              v-if="heatSeries.length != 0"
              :key="heatKey"
              type="heatmap"
              height="350"
              :options="heatChartOptions"
              :series="heatSeries"
            ></apexchart>
          </div>
        </div>
        <!-- <div class="mobile-content-unify-map" >
          <div class="mobile-content-unify-map-title">服務場站分布</div>
          <div class="mobile-content-unify-map-img" id="mobile-unify-map"></div>
        </div> -->
        <div class="mobile-content-unify-map">
          <div class="mobile-content-unify-map-title">服務場站分布</div>
          <div class="mobile-content-unify-map-item">
            <div class="mobile-content-unify-map-item-image"></div>
            <div class="mobile-content-unify-map-item-list">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#cityListModal"
                class="mobile-content-unify-map-item-list-city"
                v-for="(item, index) in lotsCity"
                :key="index"
                @click="openModal(index)"
              >
                <div class="mobile-content-unify-map-item-list-city-name">
                  {{ index }}
                </div>
                <div class="mobile-content-unify-map-item-list-city-count">
                  {{ item.length }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-content-about" id="about">
        <div class="mobile-content-about-connect">
          <div class="mobile-content-about-connect-title">聯絡我們</div>
          <div class="mobile-content-about-connect-content">
            <div class="mobile-content-about-connect-content-group">
              <div class="mobile-content-about-connect-content-group-item">
                <div
                  class="mobile-content-about-connect-content-group-item-img svg-email"
                ></div>
              </div>
              <div class="mobile-content-about-connect-content-group-item">
                <div
                  class="mobile-content-about-connect-content-group-item-text"
                >
                  service<span class="d-none">不接受廣告信件</span>@berryan.com
                </div>
              </div>
            </div>
            <div class="mobile-content-about-connect-content-group">
              <div class="mobile-content-about-connect-content-group-item">
                <div
                  class="mobile-content-about-connect-content-group-item-img svg-phone"
                ></div>
              </div>
              <div class="mobile-content-about-connect-content-group-item">
                <div
                  class="mobile-content-about-connect-content-group-item-text"
                >
                  02-26968766
                </div>
                <div
                  class="mobile-content-about-connect-content-group-item-text"
                >
                  (週一 - 五 9am - 6pm)
                </div>
              </div>
            </div>
            <div class="mobile-content-about-connect-content-group">
              <div class="mobile-content-about-connect-content-group-item">
                <div
                  class="mobile-content-about-connect-content-group-item-img svg-home"
                ></div>
              </div>
              <div class="mobile-content-about-connect-content-group-item">
                <div
                  class="mobile-content-about-connect-content-group-item-text"
                >
                  新北市汐止區新台五路一段106號18樓
                </div>
                <div
                  class="mobile-content-about-connect-content-group-item-text"
                >
                  (東方科學園區B棟)
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-content-about-map" id="mobile-about-map"></div>
      </div>
      <div class="modal" tabindex="-1" id="cityListModal">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ openCity }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body" v-if="lotsCity[openCity]">
              <div
                class="city-list"
                v-for="(item, index) in lotsCity[openCity]"
                :key="index"
              >
                {{ index + 1 }}.{{ item.address }}
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "berryanMobileNav",
    data() {
      let lastDayTotalCarsInOut = {};
      let lastDayHoursCarsInOut = [];
      let lastSecondWeekDaysCarsInOut = [];
      let lastSecondWeekDaysIncome = [];
      let paysCompare = {
        aps: {
          title: "現金",
          value: "",
          icon: "top_arrow",
        },
        fetc: {
          title: "會員",
          value: "",
          icon: "top_arrow",
        },
        card: {
          title: "刷卡",
          value: "",
          icon: "top_arrow",
        },
        deposit: {
          title: "儲值",
          value: "",
          icon: "top_arrow",
        },
        paytaipei: {
          title: "行動支付",
          value: "",
          icon: "top_arrow",
        },
      };
      let lastDayTotalCarsInTotal = 0;
      let lastDayHoursCarsInAverage = 0;
      let lastWeekMaxValue = 0;
      let effectBarX = [];
      let effectBarY = [];
      let effectBar = [];
      let reportTab = "identity";
      let pieSeries = [];
      let pieSeriesTotal = 0;
      let pieChartOptions = {
        chart: {
          height: 220,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: "25%",
              background: "transparent",
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        colors: ["#1ab7ea", "#0084ff", "#39539E"],
        grid: {
          padding: {
            bottom: 20,
          },
        },
        labels: ["月租", "臨停", "會員"],
        legend: {
          show: true,
          floating: true,
          fontSize: "12px",
          position: "bottom",
          offsetX: 0,
          offsetY: 0,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return (
              seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
            );
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 220,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      };
      let lineSeries = [];
      let lineChartOptions = {
        chart: {
          height: 240,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["週一", "週二", "週三", "週四", "週五", "週六", "週日"],
        },
        yaxis: {
          min: 70000,
          max: 110000,
          tickAmount: 5,
          labels: {
            offsetX: -20,
          },
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          floating: true,
          offsetY: 0,
          offsetX: 0,
        },
        grid: {
          padding: {
            bottom: 20,
          },
        },
      };
      let heatSeries = [];
      let heatChartOptions = {
        chart: {
          height: 350,
          type: "heatmap",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#E6DF4C"],
        toolbar: {
          show: false,
        },
      };
      let timeCheckOptions = {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        dataLabels: {
          enabled: false,
        },
        labels: ["7~8", "9~10", "11~12", "13~14", "15~16", "17~18", "19~20"],
        xaxis: {
          labels: {
            fontSize: "12px",
          },
        },
        annotations: {
          xaxis: [
            {
              x: "9~10",
              label: {
                show: true,
                text: "現在",
                orientation: "horizontal",
                borderColor: "none",
                style: {
                  color: "Red",
                  background: "transparent",
                  fontSize: "14px",
                  fontWeight: "boid",
                },
              },
            },
          ],
        },
      };
      let timeCheckSeries = [];
      let timeCheckDescription = "目前非熱門時段";
      let timeCheckDay = 0;
      let timeCheckDaysOption = [
        { label: "週日", value: 0 },
        { label: "週一", value: 1 },
        { label: "週二", value: 2 },
        { label: "週三", value: 3 },
        { label: "週四", value: 4 },
        { label: "週五", value: 5 },
        { label: "週六", value: 6 },
      ];
      let sunHeadtData = [];
      let nightHeatData = [];
      let isViewNightHeatData = false;
      let heatKey = 0;
      let paySeries = [
        {
          name: "總收入",
          data: [],
        },
      ];
      let payChartOptions = {
        chart: {
          height: 320,
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
        },
        forecastDataPoints: {
          count: 4,
        },
        fill: {
          type: "gradient",
          colors: "#77AEDE",
          gradient: {
            shade: "dark",
            gradientToColors: ["#77D0DE"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        annotations: {
          points: [],
        },
        tooltip: {
          enabled: false,
        },
      };
      let lotsCity = {};
      let openCity = "台北市";
      return {
        isMenuOpen: false,
        lastDayTotalCarsInOut,
        lastDayHoursCarsInOut,
        lastSecondWeekDaysCarsInOut,
        lastDayTotalCarsInTotal,
        lastDayHoursCarsInAverage,
        lastSecondWeekDaysIncome,
        paysCompare,
        effectBarX,
        effectBarY,
        effectBar,
        lastWeekMaxValue,
        reportTab,
        pieSeries,
        pieSeriesTotal,
        pieChartOptions,
        lineSeries,
        lineChartOptions,
        heatSeries,
        heatChartOptions,
        timeCheckOptions,
        timeCheckSeries,
        timeCheckDescription,
        timeCheckDay,
        timeCheckDaysOption,
        sunHeadtData,
        nightHeatData,
        isViewNightHeatData,
        heatKey,
        paySeries,
        payChartOptions,
        lotsCity,
        openCity,
      };
    },
    methods: {
      openMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      gotoPage(item) {
        $(".mobile-content").animate(
          { scrollTop: document.getElementById(item).offsetTop - 40 },
          500
        );
        this.isMenuOpen = false;
      },
      getLastSecondWeekDaysIncome() {
        let vm = this;
        $.ajax({
          url: "https://s3.ap-northeast-1.amazonaws.com/s3.paca.com.tw/period_report/stat/last_income_per_day.json",
          method: "GET",
          success: function (res) {
            vm.lastSecondWeekDaysIncome = res;
            let compare = function (item) {
              vm.paysCompare[item].value =
                parseInt(vm.lastSecondWeekDaysIncome[0][item]) -
                parseInt(vm.lastSecondWeekDaysIncome[1][item]);
              if (vm.paysCompare[item].value < 0) {
                vm.paysCompare[item].value = vm.paysCompare[item].value * -1;
                vm.paysCompare[item].icon = "bottom_arrow";
              } else if (vm.paysCompare[item].value == 0) {
                vm.paysCompare[item].icon = "remove";
              }
            };
            compare("aps");
            compare("fetc");
            vm.lastSecondWeekDaysIncome[0].card =
              parseInt(vm.lastSecondWeekDaysIncome[0].exit_pay) +
              parseInt(vm.lastSecondWeekDaysIncome[0].easycard);
            vm.lastSecondWeekDaysIncome[1].card =
              parseInt(vm.lastSecondWeekDaysIncome[1].exit_pay) +
              parseInt(vm.lastSecondWeekDaysIncome[1].easycard);
            compare("card");
            compare("deposit");
            compare("paytaipei");
            vm.drawPayChart();
          },
          error: function (err) {
            console.log(err);
          },
        });
      },
      getLastDayHoursCarsInOut() {
        let vm = this;
        $.ajax({
          url: "https://s3.ap-northeast-1.amazonaws.com/s3.paca.com.tw/period_report/stat/last_flow_per_hour.json",
          method: "GET",
          success: function (res) {
            vm.lastDayHoursCarsInOut = res;
            vm.drawHeatChart();
            vm.timeCheckDay = new Date().getDay();
            vm.drowTimeCheckChart();
          },
          error: function (err) {
            console.log(err);
          },
        });
        let aveg = 0;
        this.lastDayHoursCarsInOut.forEach(
          function (item) {
            aveg = aveg + Number(item.in_total);
          }.bind(this)
        );
        this.lastDayHoursCarsInAverage = parseInt(aveg / 24);
      },
      getLastSecondWeekDaysCarsInOut() {
        let vm = this;
        $.ajax({
          url: "https://s3.ap-northeast-1.amazonaws.com/s3.paca.com.tw/period_report/stat/last_flow_per_day.json",
          method: "GET",
          success: function (res) {
            vm.lastDayTotalCarsInOut = res[0];
            vm.drawIdentityChart();
            vm.lastSecondWeekDaysCarsInOut = res;
            vm.drawInOutChart();
          },
          error: function (err) {
            console.log(err);
          },
        });
      },
      drawIdentityChart() {
        this.pieSeriesTotal =
          parseInt(this.lastDayTotalCarsInOut.in_member_car) +
          parseInt(this.lastDayTotalCarsInOut.in_hourly_car) +
          parseInt(this.lastDayTotalCarsInOut.in_fetc_car);
        this.pieSeries = [
          Math.round(
            (this.lastDayTotalCarsInOut.in_member_car / this.pieSeriesTotal) *
              100
          ),
          Math.round(
            (this.lastDayTotalCarsInOut.in_hourly_car / this.pieSeriesTotal) *
              100
          ),
          Math.round(
            (this.lastDayTotalCarsInOut.in_fetc_car / this.pieSeriesTotal) * 100
          ),
        ];
      },
      drawInOutChart() {
        let lineSeries = JSON.parse(
          JSON.stringify(this.lastSecondWeekDaysCarsInOut)
        )
          .splice(0, 7)
          .reverse();
        this.lineSeries = [
          {
            name: "入場流量",
            data: [],
          },
          {
            name: "出場流量",
            data: [],
          },
        ];
        let minValue = 0;
        let maxValue = 0;
        let days = [];
        for (let i = 0; i < 7; i++) {
          lineSeries[i].in_total = parseInt(lineSeries[i].in_total);
          lineSeries[i].out_total = parseInt(lineSeries[i].out_total);
          this.lineSeries[0].data[i] = lineSeries[i].in_total;
          this.lineSeries[1].data[i] = lineSeries[i].out_total;
          if (minValue == 0 || minValue > lineSeries[i].in_total) {
            minValue = lineSeries[i].in_total;
          }
          if (maxValue < lineSeries[i].in_total) {
            maxValue = lineSeries[i].in_total;
          }
          if (minValue > lineSeries[i].out_total) {
            minValue = lineSeries[i].out_total;
          }
          if (maxValue < lineSeries[i].out_total) {
            maxValue = lineSeries[i].out_total;
          }
          days.push(lineSeries[i].date.split("").splice(5).join(""));
        }
        minValue = minValue * 0.85;
        maxValue = maxValue * 1.15;
        this.lineChartOptions.yaxis.min = Math.round(minValue);
        this.lineChartOptions.yaxis.max = Math.round(maxValue);
        this.lineChartOptions.xaxis.categories = days;
      },
      drowTimeCheckChart() {
        let changeDay = this.timeCheckDay;
        let timeCheckData = Object.values(
          JSON.parse(JSON.stringify(this.lastDayHoursCarsInOut))
        );
        timeCheckData.shift();
        let week = 0;
        //diff = in - out
        let timeViewDataIn = {
          "1th": 0,
          "2th": 0,
          "3th": 0,
          "4th": 0,
          "5th": 0,
          "6th": 0,
          "7th": 0,
        };
        let timeViewDataOut = {
          "1th": 0,
          "2th": 0,
          "3th": 0,
          "4th": 0,
          "5th": 0,
          "6th": 0,
          "7th": 0,
        };
        let timeViewDataDiff = {
          "1th": 0,
          "2th": 0,
          "3th": 0,
          "4th": 0,
          "5th": 0,
          "6th": 0,
          "7th": 0,
        };
        let focusDay = timeCheckData.find(
          (item) => item.weekday == changeDay
        ).hours;
        focusDay.forEach(
          function (secItem) {
            let AddedtimeObjectFunc = function (series) {
              timeViewDataIn[series] =
                timeViewDataIn[series] + Number(secItem.in_total);
              timeViewDataOut[series] =
                timeViewDataOut[series] + Number(secItem.out_total);
              timeViewDataDiff[series] =
                timeViewDataIn[series] - timeViewDataOut[series];
            };
            if (Number(secItem.hour) < 7) {
            } else if (Number(secItem.hour) <= 8) {
              AddedtimeObjectFunc("1th");
            } else if (Number(secItem.hour) <= 10) {
              AddedtimeObjectFunc("2th");
            } else if (Number(secItem.hour) <= 12) {
              AddedtimeObjectFunc("3th");
            } else if (Number(secItem.hour) <= 14) {
              AddedtimeObjectFunc("4th");
            } else if (Number(secItem.hour) <= 16) {
              AddedtimeObjectFunc("5th");
            } else if (Number(secItem.hour) <= 18) {
              AddedtimeObjectFunc("6th");
            } else if (Number(secItem.hour) <= 20) {
              AddedtimeObjectFunc("7th");
            }
          }.bind(this)
        );
        let now_hour = new Date().getHours();
        let annotationsDemo = {
          xaxis: [
            {
              x: "9~10",
              label: {
                show: true,
                text: "現在",
                orientation: "horizontal",
                borderColor: "none",
                offsetY: -15,
                style: {
                  color: "Red",
                  background: "transparent",
                  fontSize: "18px",
                  fontWeight: "boid",
                },
              },
            },
          ],
        };
        if (Number(now_hour) < 7) {
          this.timeCheckDescription = "目前非熱門時段";
          annotationsDemo.xaxis = [];
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 8) {
          annotationsDemo.xaxis[0].x = "7~8";
          this.timeCheckDescription =
            timeViewDataDiff["1th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["1th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 10) {
          annotationsDemo.xaxis[0].x = "9~10";
          this.timeCheckDescription =
            timeViewDataDiff["2th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["2th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 12) {
          annotationsDemo.xaxis[0].x = "11~12";
          this.timeCheckDescription =
            timeViewDataDiff["3th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["3th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 14) {
          annotationsDemo.xaxis[0].x = "13~14";
          this.timeCheckDescription =
            timeViewDataDiff["4th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["4th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 16) {
          annotationsDemo.xaxis[0].x = "15~16";
          this.timeCheckDescription =
            timeViewDataDiff["5th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["5th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 18) {
          annotationsDemo.xaxis[0].x = "17~18";
          this.timeCheckDescription =
            timeViewDataDiff["6th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["6th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else if (Number(now_hour) <= 20) {
          annotationsDemo.xaxis[0].x = "19~20";
          this.timeCheckDescription =
            timeViewDataDiff["7th"] < 0
              ? "現在時段：較多空位"
              : timeViewDataDiff["7th"] < 1000
              ? "現在時段：略有空位"
              : "現在時段：車位難尋";
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        } else {
          this.timeCheckDescription = "目前非熱門時段";
          annotationsDemo.xaxis = [];
          this.$set(this.timeCheckOptions, "annotations", annotationsDemo);
        }
        this.timeCheckSeries = [
          {
            name: "入場流量",
            data: Object.values(timeViewDataIn),
          },
          {
            name: "出場流量",
            data: Object.values(timeViewDataOut),
          },
        ];
      },
      drawPayChart() {
        this.paySeries[0].data = [];
        this.lastSecondWeekDaysIncome.splice(14);
        let dataArray = [];
        this.payChartOptions.annotations.points = [
          {
            x: 180,
            y: this.lastSecondWeekDaysIncome[4].total,
            marker: {
              size: 8,
              fillColor: "#fff",
              strokeColor: "red",
              radius: 2,
            },
            label: {
              borderColor: "#FF4560",
              offsetY: 0,
              style: {
                color: "#fff",
                background: "#FF4560",
              },
              text: "今天",
            },
          },
        ];
        this.lastSecondWeekDaysIncome.reverse().forEach(
          function (item) {
            this.paySeries[0].data.push(item.total);
            dataArray.push(item.date);
          }.bind(this)
        );
        this.payChartOptions.xaxis.categories = dataArray;
      },
      createEffectMap() {
        let vm = this;
        $.ajax({
          methods: "GET",
          url: "https://epk-admin.fetc.net.tw/api/lots",
          success: function (res) {
            vm.lotsCity = {};
            res.forEach(function (item) {
              if (vm.lotsCity[item.city]) {
                vm.lotsCity[item.city].push(item);
              } else if (item.city) {
                vm.lotsCity[item.city] = [];
              }
            });
          },
          error: function (err) {
            console.log(err);
          },
        });
      },
      drawHeatChart() {
        let resultArray = Object.values(this.lastDayHoursCarsInOut)
          .splice(1, 7)
          .reverse();
        this.sunHeadtData = [];
        this.nightHeatData = [];
        let sunPushItem = {
          name: "",
          data: [],
        };
        let nightPushItem = {
          name: "",
          data: [],
        };
        for (let i = 0; i < 24; i++) {
          for (let j = 0; j < 7; j++) {
            if (i < 8 || i > 19) {
              nightPushItem.name = `${String(i).padStart(2, "0")}:00`;
              nightPushItem.data.push({
                x: resultArray[j].date.split("").splice(5).join(""),
                y: resultArray[j].hours[i].in_total,
              });
            } else {
              sunPushItem.name = `${String(i).padStart(2, "0")}:00`;
              sunPushItem.data.push({
                x: resultArray[j].date.split("").splice(5).join(""),
                y: resultArray[j].hours[i].in_total,
              });
            }
          }
          if (sunPushItem.data.length != 0) {
            this.sunHeadtData.push(sunPushItem);
          }
          if (nightPushItem.data.length != 0) {
            this.nightHeatData.push(nightPushItem);
          }
          sunPushItem = {
            name: "",
            data: [],
          };
          nightPushItem = {
            name: "",
            data: [],
          };
        }
        this.sunHeadtData = this.sunHeadtData.reverse();
        this.nightHeatData = this.nightHeatData.reverse();
        let tempNightArray = this.nightHeatData.splice(4);
        this.nightHeatData = tempNightArray.concat(this.nightHeatData);
        this.switchHeatData();
      },
      switchHeatData() {
        if (this.isViewNightHeatData) {
          this.heatChartOptions.colors = ["#0F3F59"];
          this.heatSeries = JSON.parse(JSON.stringify(this.nightHeatData));
          this.heatKey += 1;
        } else {
          this.heatChartOptions.colors = ["#E6DF4C"];
          this.heatSeries = JSON.parse(JSON.stringify(this.sunHeadtData));
          this.heatKey += 1;
        }
      },
      openModal(city) {
        this.openCity = city;
      },
    },
    mounted() {
      this.createEffectMap();
      this.getLastDayHoursCarsInOut();
      this.getLastSecondWeekDaysCarsInOut();
      this.getLastSecondWeekDaysIncome();
      this.$nextTick(() => {
        var map = L.map("mobile-about-map", {
          scrollWheelZoom: false,
        });
        // 設定經緯度座標
        map.setView(new L.LatLng(25.061418462908186, 121.64916841759354), 17);
        // 設定圖資來源
        var osmUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
        var osm = new L.TileLayer(osmUrl, { minZoom: 12, maxZoom: 18 });
        map.addLayer(osm);

        L.marker([25.061418462908186, 121.64916841759354], {
          title: "泊律恆有限公司",
        }).addTo(map);
        // var productCarousel = document.querySelector('#product_carousel')
        // var productCarouselController = new bootstrap.Carousel(productCarousel)
        // var unifyCarousel = document.querySelector('#unify_carousel')
        // var unifyCarouselController = new bootstrap.Carousel(unifyCarousel)
      });
    },
  };
</script>
<style lang="scss">
  ::-webkit-scrollbar {
    width: 0px;
  }
  .mobile {
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
    &-meau {
      width: 100%;
      max-width: 1024px;
      position: fixed;
      background: white;
      z-index: 1001;
      &-title {
        width: 100%;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        &-empty {
          width: 20px;
        }
        &-text {
          font-size: 28px;
          color: #528cb1;
        }
        &-button {
          width: 20px;
          height: 19px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &-main {
            width: 20px;
            height: 1.5px;
            background-color: #528cb1;
            transition: all 0.3s ease-in-out;
            &::before,
            &::after {
              content: "";
              position: absolute;
              width: 20px;
              height: 1.5px;
              background-color: #528cb1;
              transition: all 0.3s ease-in-out;
            }
            &::before {
              transform: translateY(-8px);
            }
            &::after {
              transform: translateY(8px);
            }
          }
        }
      }
      &-list {
        user-select: none;
        position: absolute;
        background: white;
        height: calc(100vh - 60px);
        padding-top: 75px;
        box-sizing: border-box;
        &:not(.d-none) {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        &-item {
          font-size: 24px;
          line-height: 3;
          color: #528cb1;
          padding: 0 20px;
        }
        &-copyright {
          position: absolute;
          bottom: 40px;
          left: 0;
          width: 100%;
          text-align: center;
          color: #528cb1;
          font-size: 18px;
          box-sizing: border-box;
          padding: 0 20px;
        }
      }
    }
    &-content {
      position: absolute;
      top: 60px;
      height: calc(100vh - 60px);
      width: 100%;
      max-width: 1024px;
      background: url("~@/assets/home/home_background.svg") center center /
        cover no-repeat;
      padding: 0 60px;
      @media (max-width: 480px) {
        padding: 0 10px;
      }
      overflow-y: scroll;
      box-sizing: border-box;
      &-introduction {
        margin-top: 40px;
        margin-bottom: 40px;
        &-group {
          padding-bottom: 20px;
          &:last-of-type {
            padding-bottom: 0;
          }
          &-title {
            color: #528cb1;
            font-size: 24px;
            margin-bottom: 20px;
          }
          &-field {
            &-word {
              font-size: 18px;
              line-height: 2;
              &.margin-bottom {
                margin-bottom: 22px;
              }
            }
            &-container {
              &.button {
                margin-top: 30px;
                text-align: center;
              }
            }
          }
        }
      }
      &-product {
        margin-bottom: 40px;
        &-title {
          text-align: center;
          line-height: 1.5;
          color: #528cb1;
          font-size: 24px;
          margin-bottom: 20px;
        }
        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
          display: flex;
        }
        .carousel-item {
          &.active {
            display: flex;
          }

          height: 450px;
          align-items: center;
          justify-content: center;
          &:nth-of-type(1) {
            background-color: #779dde;
          }
          &:nth-of-type(2) {
            background-color: #77aede;
          }
          &:nth-of-type(3) {
            background-color: #77d0de;
          }
          &:nth-of-type(4) {
            background-color: #779dde;
          }
          &:nth-of-type(5) {
            background-color: #77aede;
          }
          &:nth-of-type(6) {
            background-color: #779dde;
          }
          &:nth-of-type(7) {
            background-color: #77d0de;
          }
        }
        &-content {
          &-item {
            display: flex;
            flex-direction: column;
            height: 450px;
            width: 220px;
            padding: 0 20px;
            box-sizing: border-box;
            transition: none;
            &-title {
              font-size: 24px;
              line-height: 28px;
              margin-bottom: 20px;
              margin-top: 30px;
              color: #ffee00;
              text-align: center;
            }
            &-text {
              font-size: 18px;
            }
            &-img {
              margin-bottom: 40px;
              margin-top: auto;
              width: 180px;
            }
            &.item-1 {
              background-color: #779dde;
              .mobile-content-product-content-item-img {
                display: inline-block;
                background: url("~@/assets/product/img_1.svg") center center /
                  contain no-repeat;
                height: 90px;
              }
            }
            &.item-2 {
              background-color: #77aede;
              .mobile-content-product-content-item-img {
                display: inline-block;
                background: url("~@/assets/product/img_2.svg") center center /
                  contain no-repeat;
                height: 175px;
              }
            }
            &.item-3 {
              background-color: #77d0de;
              .mobile-content-product-content-item-img {
                display: inline-block;
                background: url("~@/assets/product/img_3.svg") center center /
                  contain no-repeat;
                height: 200px;
              }
            }
            &.item-4 {
              background-color: #779dde;
              .mobile-content-product-content-item-img {
                display: inline-block;
                background: url("~@/assets/product/img_4.svg") center center /
                  contain no-repeat;
                height: 130px;
              }
            }
            &.item-5 {
              background-color: #77aede;
              .mobile-content-product-content-item-img {
                display: inline-block;
                background: url("~@/assets/product/img_5.svg") center center /
                  contain no-repeat;
                height: 260px;
              }
            }
            &.item-6 {
              background-color: #779dde;
              .mobile-content-product-content-item-img {
                display: inline-block;
                background: url("~@/assets/product/img_6.svg") center center /
                  contain no-repeat;
                height: 165px;
              }
            }
            &.item-7 {
              background-color: #77d0de;
              .mobile-content-product-content-item-img {
                display: inline-block;
                background: url("~@/assets/product/img_7.svg") center center /
                  contain no-repeat;
                height: 110px;
              }
            }
          }
        }
      }
      &-unify {
        margin-bottom: 40px;
        &-title {
          text-align: center;
          line-height: 1.5;
          color: #528cb1;
          font-size: 24px;
          margin-bottom: 20px;
        }
        &-carousel {
          margin-bottom: 20px;
        }
        .carousel-item.active,
        .carousel-item-next,
        .carousel-item-prev {
          display: flex;
        }
        .carousel-item {
          height: 450px;
          &.active {
            display: flex;
          }
          justify-content: center;
          background-color: #c3e0e8;
          border-radius: 10px;
        }
        &-item {
          width: 300px;
          height: 400px;
          border-radius: 10px;
          padding: 10px;
          &-title {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            &-select {
              background-color: transparent;
              border: none;
              font-size: 12px;
              margin: 0 10px;
              cursor: pointer;
              &:focus-visible {
                outline: none;
              }
            }
            &-description {
              display: flex;
              font-size: 12px;
              align-items: center;
              &-label {
                width: 10px;
                height: 10px;
                border-radius: 5px;
                border: 1px solid Red;
                position: relative;
                margin-right: 10px;
                &::after {
                  position: absolute;
                  content: "";
                  top: 2px;
                  left: 2px;
                  border-radius: 50%;
                  width: 4px;
                  height: 4px;
                  background-color: Red;
                }
              }
            }
          }
          &.identity {
            background-color: #c3e0e8;
          }
          &.inout {
            background-color: #c3e0e8;
          }
          &.host {
            background-color: #c3e0e8;
          }
          &.status {
            background-color: #c3e0e8;
          }
          &.predict {
            background-color: #c3e0e8;
          }
          &-pay-last {
            &-total {
              &-money {
                font-size: 32px;
              }
              &-day {
                color: #888;
                font-size: 14px;
              }
              padding: 10px 0;
              border-bottom: 1px solid #555;
            }
            &-count {
              padding: 48px 0;
              &-item {
                display: flex;
                align-items: center;
                line-height: 2;
                &-label {
                  flex: 1;
                }
                &-value {
                  margin-right: 20px;
                }
                &-icon {
                  width: 20px;
                  height: 20px;
                  &.top_arrow {
                    background: url("~@/assets/top_arrow.svg") center center /
                      contain no-repeat;
                  }
                  &.bottom_arrow {
                    background: url("~@/assets/bottom_arrow.svg") center center /
                      contain no-repeat;
                  }
                  &.remove {
                    background: url("~@/assets/remove.svg") center center /
                      contain no-repeat;
                  }
                }
              }
              &-description {
                text-align: right;
                color: #888;
                font-size: 14px;
              }
            }
          }
        }
        &-custom-item {
          width: 300px;
          background-color: #c3e0e8;
          border-radius: 10px;
          padding: 10px;
          height: 400px;
          &-host {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            &-title {
              text-align: center;
              font-size: 14px;
              font-weight: 600;
            }
            &-content {
              flex-grow: 1;
              display: flex;
              flex-direction: column-reverse;
              &-layout {
                display: flex;
                min-height: 50px;
                justify-content: space-between;
                line-height: 35px;
                border-top: 1px solid #777777;
                font-size: 12px;
                color: #373d3f;
                font-weight: 400;
              }
              &-chart {
                min-height: calc(100% - 50px);
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                &-item {
                  position: relative;
                  width: 30px;
                  height: 100%;
                  &-bar,
                  &-time-bar {
                    position: absolute;
                    left: 5px;
                    bottom: 0px;
                    width: 20px;
                  }
                  &-bar {
                    background-color: #669df6;
                    opacity: 0.6;
                  }
                  &-time-bar {
                    background-color: #e36f67;
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
        &-heat {
          border-radius: 10px;
          background-color: #b6d9e3;
          padding: 10px;
          margin-bottom: 20px;
          &-title {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
          &-control {
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            &-text {
              font-size: 14px;
              &.sunColor {
                color: #8c8942;
              }
              &.nightColor {
                color: #0f3f59;
              }
            }
            label {
              line-height: 0;
            }
            .btn-box {
              display: inline-flex;
              align-items: center;
              width: 40px;
              height: 20px;
              border-radius: 100px;
              background-color: #e6df4c;
              box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.13) inset;
              margin: 0 16px;
            }
            .btn-box .btn {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              line-height: 0;
              background-color: #fff;
              margin-left: 0; /*動畫從ml為0開始*/
              transition: 0.5s;
              box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
            }
            .checkbox {
              position: absolute;
              opacity: 0;
            }
            .checkbox:checked + .btn-box {
              background-color: #0f3f59;
            }
            .checkbox:checked + .btn-box .btn {
              margin-left: 20px;
            }
          }
        }
        &-map {
          &-title {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 20px;
          }
          &-img {
            width: 100%;
            height: 416px;
            border-radius: 10px;
          }
        }
        &-map {
          &-title {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 20px;
          }
          &-item {
            &-image {
              background: url("~@/assets/taiwan_map.svg") center center /
                contain no-repeat;
              width: 100%;
              height: 512px;
            }
            &-list {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              align-items: center;
              &-city {
                display: flex;
                border: 1px solid #888;
                border-radius: 5px;
                line-height: 30px;
                padding: 0;
                margin: 10px 20px;
                @media (max-width: 620px) {
                  margin: 10px;
                  line-height: 20px;
                }
                cursor: pointer;
                &-name {
                  width: 60px;
                  background-color: #79c6d6;
                  padding: 0 5px;
                  @media (max-width: 620px) {
                    width: 48px;
                    font-size: 12px;
                  }
                }
                &-count {
                  width: 45px;
                  text-align: center;
                  @media (max-width: 620px) {
                    width: 30px;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      &-about {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-connect {
          &-title {
            font-size: 24px;
            line-height: 2;
            color: #528cb1;
            margin-bottom: 40px;
            text-align: center;
          }
          &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            &-group {
              display: flex;
              padding-bottom: 25px;
              flex-direction: column;
              justify-content: space-evenly;
              &-item {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin-bottom: 20px;
                &-img {
                  &.svg-email {
                    background: url("~@/assets/about/email.svg") center center /
                      contain no-repeat;
                  }
                  &.svg-phone {
                    background: url("~@/assets/about/phone.svg") center center /
                      contain no-repeat;
                  }
                  &.svg-home {
                    background: url("~@/assets/about/home.svg") center center /
                      contain no-repeat;
                  }
                  width: 34px;
                  height: 34px;
                }
                &-text {
                  font-size: 18px;
                  line-height: 2;
                }
              }
            }
          }
        }
        &-map {
          width: 100%;
          height: 416px;
          margin-bottom: 100px;
        }
      }
    }
    .carousel-indicators [data-bs-target] {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      opacity: 0.2;
    }
    .carousel-indicators .active {
      opacity: 1;
    }
    .carousel-control-next,
    .carousel-control-prev {
      align-items: flex-end;
      padding-bottom: 20px;
    }
    .carousel-control-prev-icon {
      background-image: url("~@/assets/mobile/icon/arrowLeft.svg");
    }
    .carousel-control-next-icon {
      background-image: url("~@/assets/mobile/icon/arrowRight.svg");
    }
    #cityListModal {
      .city-list {
        line-height: 30px;
      }
    }
  }
</style>
